import React, { useState } from "react";
import { 
    Container, 
    Row,
    Col,
    Card,
    Form,
    Button
  } from "react-bootstrap";
  import axios from "axios"

  const RegularMap = () => {
    const mapRef = React.useRef(null);
    React.useEffect(() => {
      let google = window.google;
      let map = mapRef.current;
      let lat = "22.50109";
      let lng = "113.8858851";
      const myLatlng = new google.maps.LatLng(lat, lng);
      const mapOptions = {
        zoom: 13,
        center: myLatlng,
        scrollwheel: false,
        language:"zh-Hant",
        key:"AIzaSyAVCR7_R2DFnmaMIYMTiVMnqsgaLjCQxwk"
      };
  
      map = new google.maps.Map(map, mapOptions);
  
      const marker = new google.maps.Marker({
        position: myLatlng,
        map: map,
        animation: google.maps.Animation.DROP,
        title: "Joybot Holdings Ltd.",
      });
  
      const contentString =
        '<div class="info-window-content"><h2>Light Bootstrap Dashboard PRO React</h2>' +
        "<p>A premium Admin for React-Bootstrap, Bootstrap, React, and React Hooks.</p></div>";
  
      const infowindow = new google.maps.InfoWindow({
        content: contentString,
      });
  
      google.maps.event.addListener(marker, "click", function () {
        infowindow.open(map, marker);
      });
    }, []);
    return (
      <>
        <div className="map" id="regularMap" ref={mapRef} />
      </>
    );
  };
  const emailValidation = (value) =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    value
  );
const urlValidation = (value) => {
  let returnValue = true;
  try {
    new URL(value);
  } catch (e) {
    returnValue = false;
  } finally {
    return returnValue;
  }
  return false;
};

  function Contact() {
    const [registerEmail, setRegisterEmail] = useState("");
    const [registerEmailState, setRegisterEmailState] = useState(false);
    const [registerCompany, setRegisterCompany] = useState("");
    const [registerName, setRegisterName] = useState("");
    const [emailSubject, setEmailSubject] = useState("");
    const [emailBody, setEmailBody] = useState("");
    const [errMessage, setErrMessage] = useState("");
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const isValid = () => {
        if (!emailValidation(registerEmail) || registerCompany.length <= 0 || registerName.length <= 0 || emailSubject.length <= 1 || emailBody.length <=0) {            
            return false;
        }
        return true;
    }
    const doSubmit = () => {
        if (!isValid()) {
            setErrMessage("请填上所有的要求的字段。");
            return;
        }
        setErrMessage("");
        axios.post("https://myjoybot.com/sendcontact.php",
        {'name': registerName, 'company': registerCompany, 'email': registerEmail, 'subject':emailSubject, 'body':emailBody})
        .then(function (response) {
           setDisplaySuccess(true);
        }).catch(function (error) {
            setErrMessage("发送信息出错，请再试。");
        });
    }
    return (
        <>
        <Container>
            <Row>
                <Col>   
                <Form action="" id="RegisterValidation" method="">
              <Card style={{padding:"15px"}}>
                <Card.Header>
                  <Card.Title as="h4">联系我们</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form.Group className={
                      "has-label " +
                      (registerName.length > 0 ? "has-success" : "has-error")
                    }>
                    <label>
                      姓名 <span className="star">*</span>
                    </label>
                    <Form.Control
                      disabled={displaySuccess}
                      name="name"
                      type="name"
                      value={registerName}
                      onChange={(e) => {
                        setRegisterName(e.target.value);
                      }}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group className={
                      "has-label " +
                      (registerCompany.length > 0 ? "has-success" : "has-error")
                    }>
                    <label>
                      公司 <span className="star">*</span>
                    </label>
                    <Form.Control
                      disabled={displaySuccess}
                      name="name"
                      type="name"
                      value={registerCompany}
                      onChange={(e) => {
                        setRegisterCompany(e.target.value);
                      }}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group                    
                   className={
                      "has-label " +
                      (registerEmailState ? "has-success" : "has-error")
                    }>
                    <label>
                      邮件地址 <span className="star">*</span>
                    </label>
                    <Form.Control
                      disabled={displaySuccess}
                      name="email"
                      type="email"
                      value={registerEmail}
                      onChange={(e) => {
                        setRegisterEmail(e.target.value);
                        if (emailValidation(e.target.value)) {
                          setRegisterEmailState(true);
                        } else {
                          setRegisterEmailState(false);
                        }
                      }}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group className={
                      "has-label " +
                      (emailSubject.length > 0 ? "has-success" : "has-error")
                    }>
                    <label>
                    邮件主题 <span className="star">*</span>
                    </label>
                    <Form.Control
                      disabled={displaySuccess}
                      name="subject"
                      type="name"
                      value={emailSubject}
                      onChange={(e) => {
                        setEmailSubject(e.target.value);
                      }}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group className={
                      "has-label " +
                      (emailBody.length > 0 ? "has-success" : "has-error")
                    }>
                    <label>
                    邮件内容 <span className="star">*</span>
                    </label>
                    <Form.Control
                      disabled={displaySuccess}
                      as="textarea" rows={5}
                      name="body"
                      type="name"
                      value={emailBody}
                      onChange={(e) => {
                        setEmailBody(e.target.value);
                      }}
                    ></Form.Control>
                  </Form.Group>
                  {errMessage.length > 0 && <p className="text-danger">{errMessage}</p>}
                  {displaySuccess && <p className="text-success">谢谢联系我们！我们会根据您提供的邮件联系。</p>}
                </Card.Body>
                <Card.Footer className="text-right">
                  {!displaySuccess && <Button                    
                    className="btn-fill pull-right"
                    variant="info"
                    onClick={() => {
                        doSubmit();
                    }}
                  >
                    提交
                  </Button>}
                  <div className="clearfix"></div>
                </Card.Footer>
              </Card>
            </Form>             
                </Col>
                <Col className="ml-5">
                   <Row className="mt-auto mb-auto">
                    <div className="ml-auto mr-auto">
                      <div>
                        Joybot Holdings Ltd.
                      </div>
                      <div>
                        广东省 深圳市 南山区 前海信利康大厦 9B
                      </div>
                      <div>
                       邮政编码: 518066
                      </div>
                     </div>
                   </Row>
                   <Row className="mt-5">                
                   <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3686.082437246798!2d113.88846!3d22.501089999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3403ef1a2b3b773d%3A0x10e9097b26e7bb2b!2z5YmN5rW35L-h5Yip5bq35aSn5Y6m!5e0!3m2!1szh-CN!2sus!4v1706469379740!5m2!1szh-CN!2sus" width="600" height="450" frameborder="0" style={{border: "0px"}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                   </Row>
                </Col>
            </Row>
        </Container>
        </>
    )
  }

  export default Contact;