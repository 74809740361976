
import React from "react";
import { 
    Container, 
    Row,
    Col,
    Card
  } from "react-bootstrap";
import ReactPlayer from 'react-player'

function Homepage() {
    return (
        <>
         <Container>
            <h2>喜悦科技是一家基于视频内容的智能广告解决方案提供者。我们的独特的AI技术可以给各种视频场景植入广告，为广告商提供崭新的广告形式，为内容方创造增量价值。</h2>
            <div className="showcase-video ml-auto mr-auto">
            <ReactPlayer width="100%"  height="auto" controls={true} url="/assets/videos/showcase1.mp4"></ReactPlayer>
            </div>
            <h3 className="mt-5 mb-4">喜悦科技创建的立足点，是解决当前以下四大市场问题：</h3>
            <div>
              <Row>
                <Col>
                  <Card style={{padding:"15px"}}>
                    <Card.Title>
                    <span style={{fontWeight: 'bold'}}>优质广告位稀缺</span>
                    </Card.Title>
                    <Card.Body>
                    对用户来说，视频广告太多，但对于流媒体平台和广告主来说，优质广告位还是太少，尤其在体育赛事上，导致招商难
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                <Card style={{padding:"15px"}}> 
                    <Card.Title>
                    <span style={{fontWeight: 'bold'}}>广告成本高昂</span>
                    </Card.Title>
                    <Card.Body>
                    一般体育赛事广告技术配合度要求高，需专门场馆和硬件设备升级改造、转播室额外配合，致使体育赛事的广告成本很高。
                    </Card.Body>
                  </Card>
                </Col>
             </Row>                
             <Row>
             <Col>
                  <Card style={{padding:"15px"}}>
                    <Card.Title>
                    <span style={{fontWeight: 'bold'}}>投放资源少</span>
                    </Card.Title>
                    <Card.Body>
                    优质视频内容价格过高，视频媒体公司倾向于服务大客户，没有背景/资源/市场的中小品牌无法获得有效的投放资源。
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                <Card style={{padding:"15px"}}> 
                    <Card.Title>
                    <span style={{fontWeight: 'bold'}}>效果评估难</span>
                    </Card.Title>
                    <Card.Body>
                    广告主期待的投放效果不仅局限于覆盖率，而是精准的传播。目前很多视频媒体广告投放出去之后，观众是否看到，是否是精准用户难以追踪。
                    </Card.Body>
                  </Card>
                </Col>
             </Row>                
            </div>
            <h3 className="mt-2 mb-5">针对以上问题， 喜悦科技开发的基于AI视频广告嵌入的解决方案提供了成功路径：</h3>
            <div>
                <Row>
                    <Col>
                    <Row>
                    <Card style={{padding:"15px"}}> 
                    <Card.Title>
                    <span style={{fontWeight: 'bold'}}>01. 增加广告位</span>
                    </Card.Title>
                    <Card.Body>
                     <ul>
                        <li>
                        在视频内容中创建数字广告牌,广告与原生视频融为一体
                        </li>
                        <li>
                        在视频内容中的“任何形式+任何地方”以任何图片/3D/视频的形式体现
                        </li>
                     </ul>
                    </Card.Body>
                  </Card>
                  </Row>
                  <Row>
                  <Card style={{padding:"15px"}}> 
                      <Card.Title>
                        <span style={{fontWeight: 'bold'}}>02. 降低成本</span>
                      </Card.Title>
                      <Card.Body>
                         <ul>
                            <li>
                             基于人工智能算法，无需合作伙伴额外增加设备、升级改造，根据广告商的预算定制广告形式，大大降低广告成本
                            </li>
                         </ul>
                      </Card.Body>
                     </Card>
                  </Row>
                    </Col>
                    <Col>
                     <img src={require("assets/img/joybot_solution.png")} alt="...">
                     </img>
                    </Col>
                    <Col>
                    <Row>
                    <Card style={{padding:"15px"}}> 
                      <Card.Title>
                        <span style={{fontWeight: 'bold'}}>03. 精准投放</span>
                      </Card.Title>
                      <Card.Body>
                         <ul>
                            <li>
                            根据广告商的需求和预算，通过AI算法自动识别视频内容生成广告，确保广告可精准触达用户
                            </li>
                         </ul>
                      </Card.Body>
                     </Card>
                     </Row>
                     <Row>
                     <Card style={{padding:"15px"}}> 
                      <Card.Title>
                        <span style={{fontWeight: 'bold'}}>04. 消除信息不对称</span>
                      </Card.Title>
                      <Card.Body>
                         <ul>
                            <li>
                            增加优质视频的广告位并且不影响视频媒体大客户的权益，使得中小品牌有机会以降低成本获得有效的广告投放
                            </li>
                         </ul>
                      </Card.Body>
                     </Card>
                     </Row>
                  </Col>
                </Row>
            </div>
            <div>
                <h3>如果您是广告商或者内容平台，想与我们合作，请访问<a href="/?r=contact">联系我们。</a></h3>
            </div>
         </Container>
        </>
    )
}
export default Homepage;