import React from "react";
import { 
    Container, 
    Row,
    Col,
    Card,
    Table
  } from "react-bootstrap";

  function Tech() {
    return (
        <>
          <Container>
            <h3>
                喜悦科技基于AI独特的技术方案具有四多一低特点：
            </h3>
            <img style={{width:"80%"}} src={require("assets/img/joybot_solution1.png")} alt="..."></img>
            <h3 className="mt-5 mb-0">
              无需合作伙伴额外投入:
            </h3>
            <img style={{width:"80%"}} src={require("assets/img/joybot_solution2.png")} alt="..."></img>
            <h3>
            流程简、操作易、效果佳：
            </h3>
            <img style={{width:"80%"}} src={require("assets/img/joybot_solution3.png")} alt="..."></img>
            <h3 className="mt-5 mb-0">
            喜悦科技独特技术优势:
            </h3>
            <img style={{width:"80%"}} src={require("assets/img/joybot_solution4.png")} alt="..."></img>
            <img style={{width:"80%"}} src={require("assets/img/joybot_solution5.png")} alt="..."></img>
            <Card style={{padding:"15px"}}>
                <Card.Title>
                <h3 style={{fontWeight: "bold"}}>喜悦科技知识产权</h3>
                </Card.Title>
                <Card.Body>
                    <ul>
                      <li>
                      JOYBOT在对象检测算法、自然语言处理（NLP）、云计算、PaaS等领域拥有中国自主知识产权
                      </li>
                      <li>
                      目前已经<span style={{fontWeight: "bold",fontSize:"150%"}}>6</span>项中国专利,
                      <span style={{fontWeight: "bold",fontSize:"150%"}}>1</span>项美国专利在申请中，<span style={{fontWeight: "bold",fontSize:"150%"}}>10+</span>以上专利在筹备中
                      </li>
                    </ul>
                    <Row>
                        <Col>
                        <img style={{width:"80%"}} src={require("assets/img/patent1.png")} alt="..."></img>
                        </Col>
                        <Col>
                        <img  style={{width:"80%"}} src={require("assets/img/patent2.png")} alt="..."></img>
                        </Col>
                    </Row>
                    <Table bordered className="mt-5">
                        <tbody>
                        <tr>
                           <td>序号</td>
                           <td>申请区域</td>
                           <td>申请类型</td>
                           <td>申请人</td>
                           <td>申请号</td>
                           <td>案件状态</td>
                        </tr>
                            <tr>
                                <td>1-A</td>
                                <td>中国</td>
                                <td>发明</td>
                                <td>深圳喜悦机器人公司</td>
                                <td>2020112825585</td>
                                <td>实质审查</td>
                            </tr>
                            <tr>
                                <td>1-B</td>
                                <td>美国</td>
                                <td>发明</td>
                                <td>深圳喜悦机器人公司</td>
                                <td>17/160,491</td>
                                <td>实质审查</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>中国</td>
                                <td>发明</td>
                                <td>深圳喜悦机器人公司</td>
                                <td>2021103822633</td>
                                <td>实质审查</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>中国</td>
                                <td>发明</td>
                                <td>深圳喜悦机器人公司</td>
                                <td>2021106109667</td>
                                <td>实质审查</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>中国</td>
                                <td>发明</td>
                                <td>深圳喜悦机器人公司</td>
                                <td>2021108895842</td>
                                <td>实质审查</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>中国</td>
                                <td>发明</td>
                                <td>深圳喜悦机器人公司</td>
                                <td>2021108838939</td>
                                <td>实质审查</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>中国</td>
                                <td>发明</td>
                                <td>深圳喜悦机器人公司</td>
                                <td>2021108838924</td>
                                <td>实质审查</td>
                            </tr>

                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            <h3>如果您是广告商或者内容平台，想与我们合作，请访问<a href="/?r=contact">联系我们。</a></h3>
          </Container>
        </>
    )
  }

  export default Tech;