import React from "react";
import { Link, useLocation } from "react-router-dom";
// react-bootstrap components
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Pagination,
  Container,
  Row,
  Col,
  Collapse,
} from "react-bootstrap";

function AdminNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  return (
    <>
      <Navbar expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <a href="/">
              <img style={{width:"100px"}} src={require("assets/img/joybot.png")} alt="..." />
            </a>
         </div>
          <button
            className="navbar-toggler navbar-toggler-right border-0"
            type="button"
            onClick={() => setCollapseOpen(!collapseOpen)}
          >
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
          </button>
          <Navbar.Collapse className="justify-content-end" in={collapseOpen}>
            <Nav defaultActiveKey="/home" navbar>
             <Nav.Item className="mr-1">
                <Nav.Link eventKey="/home" to="/home" as={Link}>
                  首页
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="mr-1">
                <Nav.Link eventKey="/tech" to="/tech" as={Link}>
                  喜悦技术
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="mr-1">
                <Nav.Link eventKey="/usecases" to="/usecases" as={Link}>
                  解决案例
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="mr-1">
                <Nav.Link eventKey="/about" to="/about" as={Link}>
                  公司介绍
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="mr-1">
                <Nav.Link eventKey="/contact" to="/contact" as={Link}>
                  联系我们
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
