import React from "react";
import { 
    Container, 
    Row,
    Col,
    Card
  } from "react-bootstrap";

  function Company() {
    return (
        <>
        <Container>
            <h3>喜悦科技团队</h3>
            <Row>
                <Col>
                  <Card style={{padding:"15px"}}>
                    <Card.Title>
                        <div>
                         <div className="text-center"><span style={{fontWeight: "bold"}}>张彤 (Tong Zhang)</span></div>  
                         <div className="text-center"><span style={{fontWeight: "bold"}}>创始人兼CEO</span></div>
                        </div>
                    </Card.Title>
                    <Card.Body>
                        <ul>
                            <li>
                            科技技术领域投资经验丰富，包括FACEBOOK（NASDAQ：FB), AMPRIUS(NYSE:AMPX)。
                            </li>
                            <li>
                            传媒领域成功投资经验（梦舟影视sz600255）。
                            </li>
                            <li>
                              帧彩AI创始人。
                            </li>
                            <li>
                            在投融资和跨境运营方面拥有20多年的经验，参与超过135亿美元的投融资和并购项目。
                            </li>
                        </ul>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card style={{padding:"15px"}}>
                    <Card.Title>
                        <div>
                         <div className="text-center"><span style={{fontWeight: "bold"}}>吴辉 </span></div>
                         <div className="text-center"><span style={{fontWeight: "bold"}}>联合创始人兼CTO </span></div>
                       </div>
                    </Card.Title>
                    <Card.Body>
                        <ul>
                            <li>
                            自然语言处理（NLP）专家。
                            </li>
                            <li>
                            雅虎搜索数据挖掘，谷歌NLP和机器学习的主要成员，阿里巴巴NLP创始人员，百度NLP早期核心人员。
                            </li>
                            <li>
                            吴辉先生拥有19项发明专利，涉及搜索引擎、对话引擎、语音识别等领域。在主要学术期刊发表论文15篇。
                            </li>
                            <li>
                            吴辉先生拥有10年以上全球顶尖科技公司核心技术工作经验。
                            </li>
                        </ul>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card style={{padding:"15px"}}>
                    <Card.Title>
                        <div>
                          <div className="text-center"><span style={{fontWeight: "bold"}}>吴虎明</span></div>
                          <div className="text-center"><span style={{fontWeight: "bold"}}>联合创始人兼CIO</span></div>
                        </div>
                    </Card.Title>
                    <Card.Body>
                        <ul>
                            <li>
                            图像识别、咨询检索专家。
                            </li>
                            <li>
                            Apple 地图搜索团队主要领导成员，雅虎网页实时搜索的关键技术成员。
                            </li>
                            <li>
                            吴虎明先生拥有10项发明专利，涉及搜索引擎、对话引擎、语音识别等领域。
                            </li>
                            <li>
                            吴虎明先生拥有20多年全球顶尖科技公司核心技术工作经验。
                            </li>
                        </ul>
                    </Card.Body>
                  </Card>
                </Col>
            </Row>
            <h3>喜悦科技顾问</h3>
            <Row>
                <Col>
                  <Card style={{padding:"15px"}}>
                    <Card.Title>
                      <div>  
                       <div className="text-center"><span style={{fontWeight: "bold"}}>Max Baucus</span></div>
                       <div className="text-center"><span style={{fontWeight: "bold"}}>首席战略顾问</span></div>
                       </div>
                    </Card.Title>
                    <Card.Body>
                        <ul>
                            <li>
                            斯坦福大学法学博士学位，曾任美国第十一任驻华大使、美国参议院财政委员会前主席，在美国联邦参议院任职超过30。
年
                            </li>
                            <li>
                            Max Baucus先生运用其丰富的专业知识、精准的市场趋势判断以及强大的资源整合能力，为喜悦技术和全球化发展提供建议和指导。
                            </li>
                        </ul>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card style={{padding:"15px"}}>
                    <Card.Title>
                    <div>
                       <div className="text-center"><span style={{fontWeight: "bold"}}>魏冰 （Bing Wei）</span></div>
                       <div className="text-center"><span style={{fontWeight: "bold"}}>战略顾问 </span></div>
                       </div>
                    </Card.Title>
                    <Card.Body>
                        <ul>
                            <li>
                            拥有 25年企业媒体营销经验，致力于协助亚裔美国公司在美国和英国拓展业务。
                            </li>
                            <li>
                            在前美国总统克林顿和布什的指导下，被选为总统领导力学者（PLS），获得 20 亿美元投资，为加州创造 3000个就业岗位。
                            </li>
                            <li>
                            积极参与尖端技术公司的跨国发展、并购和上市。
                            </li>
                            <li>
                            哈佛商学院高管教育之“董事会中的女性“项目校友。
                            </li>
                        </ul>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card style={{padding:"15px"}}>
                    <Card.Title>
                        <div>
                        <div className="text-center"><span style={{fontWeight: "bold"}}>孙纲（Kang Sun）</span></div>
                        <div className="text-center"><span style={{fontWeight: "bold"}}>战略顾问</span></div>
                        </div>
                    </Card.Title>
                    <Card.Body>
                        <ul>
                            <li>
                            美国布朗大学材料学博士学位，2020年亚洲最具影响力科技企业家。
                            </li>
                            <li>
                            孙博士目前担任美国安普瑞斯首席执行长，在领导安普瑞斯公司之前，在新能源和科技领域成功带领2个公司上市，创办的3个公司被收购。
                            </li>
                            <li>
                            孙博士在公司海外业务合作以及资本化方面提供建议和指导。
                            </li>
                        </ul>
                    </Card.Body>
                  </Card>
                </Col>

            </Row>
        </Container>
        </>
    )
  }

  export default Company;