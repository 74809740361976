import React from "react";
import { 
    Container, 
    Row,
    Col,
    Card
  } from "react-bootstrap";
  import ReactPlayer from 'react-player'

  function Usecases() {
    return (
        <>
        <Container>
            <Card style={{padding:"15px"}}>
                <Card.Title>
                    <h3>CBA比赛实时转播</h3>
                </Card.Title>
                <Card.Body>
                <div  className="ml-auto mr-auto w-75">
                  <ReactPlayer width="100%"  height="auto" controls={true} url="/assets/videos/media2.mp4"></ReactPlayer>
                </div>
                </Card.Body>
            </Card>
            <Card style={{padding:"15px"}}>
                <Card.Title>
                    <h3>电视体育节目</h3>
                </Card.Title>
                <Card.Body>
                <div  className="ml-auto mr-auto w-75">
                <ReactPlayer width="100%"  height="auto" controls={true} url="/assets/videos/media4.mp4"></ReactPlayer>
                </div>
                </Card.Body>
            </Card>
            <Card style={{padding:"15px"}}>
                <Card.Title>
                    <h3>电视生活综艺节目</h3>
                </Card.Title>
                <Card.Body>
                <div  className="ml-auto mr-auto w-75">
                <ReactPlayer width="100%"  height="auto" controls={true} url="/assets/videos/media5.mp4"></ReactPlayer>
                </div>
                </Card.Body>
            </Card>
            <Card style={{padding:"15px"}}>
                <Card.Title>
                    <h3>电视剧</h3>
                </Card.Title>
                <Card.Body>
                 <Row>
                    <Col>   
                     <ReactPlayer width="100%"  height="auto" controls={true} url="/assets/videos/media1.mp4"></ReactPlayer>
                    </Col>
                    <Col>   
                     <ReactPlayer width="100%"  height="auto" controls={true} url="/assets/videos/media3.mp4"></ReactPlayer>
                    </Col>
                </Row>
                {/*<Row>
                    <Col>   
                     <ReactPlayer width="100%"  height="auto" controls={true} url="/assets/videos/media5.mp4"></ReactPlayer>
                    </Col>
                    <Col>   
                     <ReactPlayer width="100%"  height="auto" controls={true} url="/assets/videos/media3.mp4"></ReactPlayer>
                    </Col>
                </Row>*/}

                </Card.Body>
            </Card>

        </Container>
        </>
    )
  }

  export default Usecases;