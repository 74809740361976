import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Pagination,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function AdminFooter() {
  return (
    <>
      <footer className="footer justify-content-center">
        <div className="justify-content-center">
            <p className="copyright text-center">
              © 2024
              <a href="https://myjoybot.com">Joybot Holdings Ltd. (喜悦科技)</a>
            </p>
        </div>
      </footer>
    </>
  );
}

export default AdminFooter;
